import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',[_c(VRow,{attrs:{"justify":"space-between","no-gutters":""}},[_c('h1',{staticClass:"primary--text"},[_vm._v("Utilisateurs")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.createUser}},[_vm._v("Créer un utilisateur")])],1),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Nom ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Groupe ")]),_c('th',{staticClass:"text-left"},[_vm._v(" E-mail ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Actions ")])])]),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('td',[_vm._v(_vm._s(user.first_name + " " + user.last_name))]),_c('td',[_vm._v(_vm._s(user.group))]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[(_vm.logged_user.group === 'Administrateur' || user.id === _vm.logged_user.id)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editUser(user)}}},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" Modifier ")],1):_vm._e()],1)])}),0)]},proxy:true}])}),_c(VRow,{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Autres actions")])]),_c(VCol,{attrs:{"cols":"12"}},[(_vm.logged_user.group === 'Administrateur')?_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.generateDefaultGroups}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Générer les groupes par défaut ")],1):_vm._e()],1)],1)],1),_c(VDialog,{attrs:{"min-height":"400","max-width":"600"},model:{value:(_vm.userDialog),callback:function ($$v) {_vm.userDialog=$$v},expression:"userDialog"}},[_c('UserForm',{key:_vm.selected_user_id,attrs:{"user":_vm.selected_user,"operation":_vm.operation},on:{"reloadUsers":_vm.reloadUsers}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }